import React from 'react';
import './../Component/custom.css'
const Magicpad = () => {
    return (
        <div className="t-series">
            <br></br>
            {/* <h2>T Series</h2> */}
            <p style={{ textAlign: 'justify' }}>
                The Magicpad series is highly popular for shelf displays, offering a specialized size of 1000x93.75mm and 750x93.75mm. It features a P1.9 pixel pitch with GOB technology, providing exceptional protection against collisions, moisture, and dust, making it perfect for open environments like shopping mall shelves.
            </p>
            <div className="headingpdf" style={{ width: "215px", borderBottom: "2px solid white" }}>
                <h1 >FEATURES</h1>
            </div>
            <br></br>
            <ul>
                <li>Special size as: 1000x93.75mm/ 750x93.75mm </li>
                <li>P1.9 with GOB technology, anti-collision, moisture-proof and dust-proof, very strong protective for open environment such as shopping mall shelves.</li>
                {/* <li>Supported 45° edge-cutting, more creative shapes </li> */}

            </ul>
        </div>
    );
};

export default Magicpad;
