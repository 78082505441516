import React from 'react';
import './../Component/custom.css'
const PtSeries = () => {
    return (
        <div className="t-series">
            <br></br>
            {/* <h2>T Series</h2> */}
            <p style={{ textAlign: 'justify' }}>
                The PT Series is the industry’s best-selling aluminum cabinet, known for its robust features and superior performance. It offers IP66 protection on both the front and back sides, as well as 5VB fireproofing, making it capable of withstanding the challenging conditions in Kuwait.
            </p>
            <div className="headingpdf" style={{ width: "215px", borderBottom: "2px solid white" }}>
                <h1 >FEATURES</h1>
            </div>
            <br></br>
            <ul>
                <li>All cabinet& modules die-casting, with high precision and efficient heat-dissipation, no need A/C or fans for any size. </li>
                <li>CCES (common cathode energy saving), save power consumption through the way of respective voltage power supply to RGB lamp. </li>
                <li><b>Optional:</b>

                    <ul>
                        <li>Dual Power supply& receiving cards, never black when accident happens. </li>
                        <li>Extremely high brightness up to 9,000-10,000nits </li>
                        <li>Supported 45° cutting</li>
                        <li>Matched with VR to make perfect seamless curvature with naked 3D effect </li>
                    </ul>
                </li>

            </ul>
        </div>
    );
};

export default PtSeries;
