import React from 'react';
import './../Component/custom.css'
const TMax = () => {
    return (
        <div className="t-series">
            <br></br>
            {/* <h2>T Series</h2> */}
            <p style={{ textAlign: 'justify' }}>
                The TMAX Series shares the same platform design as the T Series, offering a typical screen size of 16:9. One panel measures 1000x562.5mm, equivalent to a 45-inch TV. This series is known for its ultra-thin and lightweight design, with a thickness of just 28.5mm (including modules) and a weight of 21.2kg/sqm.
            </p>
            <div className="headingpdf" style={{ width: "215px", borderBottom: "2px solid white" }}>
                <h1 >FEATURES</h1>
            </div>
            <br></br>
            <ul>
                <li>Ultra thin& light, 28.5mm( including modules) and 21.2kg/sqm  </li>
                <li>Optional pixel pitch: P1.5/ P1.9/ P2.6/ P3.9  </li>
                <li> Each 2 different size can be assembled together (mm): 1,000*562.5/ 500*562.5/ 250*562.5.
                    <br></br>
                    1,000*281.25/ 750*281.25/ 500*281.25  <br></br>1. 45° cutting optional  </li>
                {/* <li>Support 45°cutting angle customization, 500*500mm box can be made into cube shape (6 sides) </li> */}

            </ul>
        </div>
    );
};

export default TMax;
